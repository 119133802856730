<template>
  <div class="category-tab shared-index">
    <el-row class="table-title" type="flex" align="center">
      <div class="table-title__left">
        <el-tabs v-model="activeTab" type="card" @tab-click="handleChangeTab">
          <el-tab-pane v-for="tab in placeTypes" :key="tab.value" :name="tab.value">
            <div slot="label">
              {{ tab.name }}
              <span v-if="activeTab === tab.value">({{ total }})</span>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div>
        <el-button
          type="primary"
          icon="el-icon-circle-plus-outline"
          size="small"
          @click="createCategory"
        >Thêm</el-button>
        <el-button
          type="primary"
          icon="el-icon-refresh-right"
          size="small"
          @click="handleChangeTab"
        >Làm mới</el-button>
      </div>
    </el-row>
    <list-categories
      :loading="callingAPI"
      :data="allCategories"
      :type="activeTab"
      :place-types="placeTypes"
      @reload="handleReloadAfterDelete"
      @update="updateCategory"
    ></list-categories>
    <el-row type="flex" justify="end" class="my-4">
      <el-pagination
        :current-page="page"
        :page-size="limit"
        :total="total"
        background
        layout="total, prev, pager, next"
        @current-change="handleChangePage"
      ></el-pagination>
    </el-row>
    <update-category-popup
      v-if="visibleUpdate"
      :category="selectedCategory"
      :visible="visibleUpdate"
      :type="activeTab"
      @close="visibleUpdate = false"
      @reload="loadData"
      @reload-constant="handleReloadConstant"
    ></update-category-popup>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapGetters, mapActions } from 'vuex'
import { getCategory } from '@/services/category'
import ListCategories from './components/List.vue'
import UpdateCategoryPopup from './components/popup/Update.vue'
import { getPlaceTypes, getTranslation } from '@/utils/filters'

import TYPE from '@/data/type'

const pTypes = getPlaceTypes(false, ['hierarchy'])

export default {
  name: 'Category',
  components: {
    ListCategories,
    UpdateCategoryPopup
  },
  data() {
    return {
      visibleUpdate: false,
      callingAPI: false,
      allCategories: [],
      selectedCategory: {},
      activeTab: pTypes[0].value,
      total: 0,
      limit: 10,
      page: 1,
      searchText: '',
      placeTypes: pTypes
    }
  },
  computed: {
    ...mapGetters(['language']),
    placeType() {
      return TYPE.PLACES.find((p) => p.value === this.activeTab)
    },
    params() {
      const p = {
        page: this.page,
        per_page: this.limit,
        locale: this.language
      }
      return { ...p, place_type: this.activeTab }
    }
  },
  watch: {
    language: 'loadData'
  },
  beforeMount() {
    this.loadData()
  },
  methods: {
    ...mapActions('public', ['updateReloadConstant']),

    loadData() {
      this.callingAPI = true
      this.visibleUpdate = false
      getCategory(this.params, this.activeTab).then((response) => {
        this.allCategories = response.result.map((r) => ({
          ...r,
          name: getTranslation(r, this.language) ? getTranslation(r, this.language).name : ''
        }))
        this.total = response.total
        this.callingAPI = false
      }).catch(() => {
        this.callingAPI = false
      })
    },
    handleChangePage(page) {
      this.page = page
    },
    createCategory() {
      this.selectedCategory = null
      this.visibleUpdate = true
    },
    updateCategory(data) {
      this.selectedCategory = data
      this.visibleUpdate = true
    },
    handleChangeTab() {
      this.page = 1
      this.loadData()
    },
    handleReloadAfterDelete() {
      if (this.allCategories.length === 1 && this.page > 1) {
        this.page = this.page - 1
      }
      this.loadData()
      this.handleReloadConstant()
    },
    handleReloadConstant() {
      this.updateReloadConstant(dayjs().unix())
    }
  }
}
</script>

<style lang="scss" scoped>
.category-tab {
  .table-title {
    &__left {
      max-width: calc(100% - 200px);
      .el-tabs {
        max-width: 100%;
      }
    }
  }
}
</style>
